import React from 'react';

const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/others/jp-charles.jpeg"} alt="JP-charles" />
                        <p className='pie_de_foto'><span>Charles Hoskinson (Cardano Founder) & Jotape at the ALBA Lab diploma ceremony. <br></br> National Technological University (UTN) in Buenos Aires, Argentina, July 2024.</span></p>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Our mission</span>
                            <h2 className="title">Preserve Cardano's History</h2>
                            <p>We seek to facilitate the onboarding of new users to our ecosystem. Helping them to understand and have a macro (and comprenhensive) vision of Cardano.<br></br><br></br>We compile and consolidate the most important milestones. Then we disseminate them as educational content, in the form of infographics and NFT collections.</p>
                            {/* <p>Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris.</p> */}
                            {/* <Link to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link> */}
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <img src={process.env.PUBLIC_URL + "/images/others/constitutional-convention-2024.png"} alt="Constitutional Convention" /> 
                    <div className="col-xl-12 col-lg-12"> 
                        <p className='pie_de_foto'><span>Signing Ceremony, Constitutional Convention at the School of Law, University of Buenos Aires (UBA), Argentina, December 2024.</span></p> 
                    </div>
                </div>
                <div className="row">
                    <img src={process.env.PUBLIC_URL + "/images/others/cardano-summit-2022.png"} alt="Cardano Summit" /> 
                    <div className="col-xl-12 col-lg-12"> 
                        <p className='pie_de_foto'><span>Cardano Summit in Buenos Aires, Argentina, September 2022.</span></p> 
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;