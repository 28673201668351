import React from 'react';
import { Link } from 'react-router-dom';
// import CounterUp from '../counterup/CounterUp';
import { slugify } from '../../utils';



const ResearchTimelineArea2025 = () => {
    return (
        <div className="section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="heading">
                            <span className="subtitle">Collection</span>
                            <h2 className="title">Research Timeline 2025</h2>   
                            <div className="nft-full-center">
                            <a href={process.env.PUBLIC_URL + "/research-timeline-2025"}><img className="nft-full-center" src={process.env.PUBLIC_URL + "/images/nft/research-timeline-2025/RT2025_preview_wide.png"} alt="ctimelines"/></a>
                            </div>                        
                            <br></br><br></br>
                            <Link to={process.env.PUBLIC_URL + "/research-timeline-2025"} className="axil-btn btn-large btn-fill-primary">View Collection</Link>
                            {/*<a href="https://pay.nmkr.io/?p=918205537897425da5d6003090749e23&c=1" className="axil-btn btn-fill-promo btn-large" style={{ marginLeft: '20px' }}>Get NFT</a>*/}
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    )
}


export default ResearchTimelineArea2025;