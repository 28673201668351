import React, { useEffect } from 'react';
import FooterSimple from '../common/footer/FooterSimple';
import HeaderNeg from '../common/header/HeaderNeg';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColecctionResearchTimeline2025 from '../component/timelines/ColecctionResearchTimeline2025';
import SectionResearchTimeline2025 from '../component/timelines/SectionResearchTimeline2025';
import BannerResearchTimeline2025 from '../component/banner/BannerResearchTimeline2025';
import BannerYouTubeRT2025 from '../component/banner/BannerYouTubeRT2025';
import ReactGA from 'react-ga';

const ResearchTimeline2025 = () => {

    useEffect(() => {
        ReactGA.pageview('/cardano-timeline');
    }, []);

    return (
        <>
        <SEO title="Reseach Timeline 2025" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderNeg />
            <BannerResearchTimeline2025 />
            <div className="youtube-content bg-color-light">   
                <BannerYouTubeRT2025 /> 
            </div>  
            <ColecctionResearchTimeline2025 />
            <SectionResearchTimeline2025 />             

            <div className="section section-padding" id="section1">
                <div className="container">
                </div>
            </div>
                 
            <CtaLayoutOne />
            <FooterSimple />
        </main>
        </>
    )
}

export default ResearchTimeline2025;