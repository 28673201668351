import React from 'react';
// import { Link } from 'react-router-dom';
// import NftDataCardanoTimeline2023 from "../../data/nft/NftDataCardanoTimeline2023.json";
// import { slugify } from '../../utils';

// const allData = NftDataCardanoTimeline2023;

const ColecctionCardanoTimeline2023 = () => {
    return (
        <div className="section section-padding pb--70 pb_lg--20 pb_md--0">
            <div className="container">
                <div className="section-heading heading-dark">
                    <span className="subtitle">Collection</span>
                    <h2 className="title mb--50">Cardano Timeline 2023</h2>
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>

    )
}

export default ColecctionCardanoTimeline2023;