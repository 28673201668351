import React from 'react';

const BannerYouTubeCT2024 = () => {

    return (
        <div className="banner banner-style-youtube">
            <div className="video-responsive">
                <iframe 
                src={`https://www.youtube.com/embed/DLXLJT9CfbQ`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
        </div>
    )
}

export default BannerYouTubeCT2024;