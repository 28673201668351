import React from 'react';
// import { Link } from 'react-router-dom';
// import NftDataCardanoTimeline2024 from "../../data/nft/NftDataCardanoTimeline2024.json";
// import { slugify } from '../../utils';

// const allData = NftDataCardanoTimeline2024;

const ColecctionCardanoTimeline2024 = () => {
    return (
        <div className="section section-padding pb--70 pb_lg--20 pb_md--0">
            <div className="container">
                <div className="section-heading heading-dark">
                    <span className="subtitle">NFT Collection</span>
                    <h2 className="title mb--50">Cardano Timeline 2024</h2>
                    <a target={'_blank'} href="https://pool.pm/policy/540cfdf03f88f80ef8354ee7f22663b7f157cf3780bd32af4db632ca">Policy ID: 540cfdf03f88f80ef8354ee7f22663b7f157cf3780bd32af4db632ca</a>
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>

    )
}

export default ColecctionCardanoTimeline2024;